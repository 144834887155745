import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <section className="bg-gray-50 text-gray-700 py-4 sm:py-10">
      <div className="container mx-auto px-4">
        <h3 className="text-2xl sm:text-3xl text-center mb-4">
          The page you’re looking for can’t be found.
        </h3>
        <div className="flex flex-wrap justify-center lg:w-10/12 mx-auto">
          <p>
            The page you're looking for has been moved, renamed, or it no longer
            exists. Please use the navigation at the top of the site to return
            to our site.
          </p>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
